.container-page{
    min-height: 100vh;
    background-color: #e7e7e7;

}

.card-login{
    min-height: 380px;
    box-shadow: 3px 4px 5px 2px #ccccccb3;
    border-radius: 10px;
    padding: 25px;
    background-color: white;

}

.container-input-login{
    background-color: #f7f5f59e;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 2px 2px 1px -1px #cccccc;
    > input{
        background-color: transparent;
        border: none;
        min-height: 37px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
    }
    > input::placeholder{
        color: rgb(167, 165, 165);
    }
    > input:focus{
        border: none;
        background-color: #f7f5f59e;

    }
   
}
.header-title{
    font-family: "Montserrat",  sans-serif;
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 600;
}
.btn-login{
    padding: 10px 15px;
    background-color: rgb(0, 57, 100);
    color: white;
    font-size: 16px;
    min-width: 200px;
    border-radius: 10px;
    border: 1px solid rgb(0, 57, 100);
    box-shadow: 2px 2px 4px 2px #ccc;
    font-family: "Montserrat",  sans-serif;
    font-weight: 400;
    font-style: normal;
}
.user-logged{
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 600;
    font-style: normal;
}

.dropdown-app{
    min-width: 250px;
    max-width: 250px;
    background-color: transparent;
    border-radius: 25px;
    border: 1px solid rgb(0, 57, 100);
    color: black;
}

.dropdown-app::after{
    float: right;
    position: relative;
    right: -5px;
    vertical-align: middle;
    margin-top: 3%;
    margin-left: 5px;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
}


.store-drop-down > .dropdown-item{
    color: #4f4f4f  !important;
}
.store-drop-down > .dropdown-item.selected{
    color: white  !important;
    background-color: rgb(0, 57, 100);
    font-weight: 600;
}
.store-drop-down > .dropdown-item:hover{
    background-color: #a6a6a6;
}
.dropdown-store-menu{
    min-width: 250px;
    max-width: 250px;
    background-color: transparent;
    border-radius: 25px;
    // border: 1px solid rgb(0, 57, 100);
    border: none !important;
    color: white;
}
.dropdown-store-menu:hover,.dropdown-store-menu:focus,.dropdown-store-menu:active,.show > .btn.dropdown-toggle{
   background-color: rgb(0, 57, 100) !important;
}
.dropdown-store-menu::after{
    float: right;
    position: relative;
    right: -5px;
    vertical-align: middle;
    margin-top: 3%;
    margin-left: 5px;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
}
.store-drop-down{
   width: 250px;
   z-index: 5;
}
.sidebar-wrapper.schedule{
    z-index: 3!important;
}
.schedule.sidebar:after{
    z-index: 0!important;
}
.schedule-message-error{
    color: rgb(196 29 29);
}
.css-1atu56z-MuiPaper-root{
    box-shadow: none!important;
}

.MuiTableCell-head{
    background-color: rgb(0, 57, 100);
}
.MuiTableHead-root.css-118e0ip-MuiTableHead-root{
 
   tr{
        background-color: rgb(0, 57, 100) !important;
    }
    tr th{
        color: white; 
    }
    tr th:first-child{
        border-top-left-radius: 10px;
    }
    tr th:last-child{
        border-top-right-radius: 10px;
    }
    tr th .Mui-TableHeadCell-Content > .MuiBadge-root.css-1c32n2y-MuiBadge-root{
        color: white; 
    }
}
.css-1j43wpm-MuiButtonBase-root-MuiTableSortLabel-root .MuiTableSortLabel-icon{
    color: white !important; 
}
.css-118d58w-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: white !important; 
}
.css-1p8dotb-MuiButtonBase-root-MuiIconButton-root {
    color: white !important; 
}

.css-1j43wpm-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon{
    color: white !important; 
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root{
    color: white !important;
}

.header-row{
    background-color: rgb(0, 57, 100);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;;

>div{
    color: white;
}

}

.btn-start{
    background-color: transparent;
    border-radius: 25px;
    border: 0.5px solid #ccc;
    padding: 5px 10px;
    font-weight: 500;
    background-color: rgb(0, 57, 100);
    color: white;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.header-table-row tr th {
    background-color: rgb(0, 57, 100);
    color: white;
}
   
.header-table-row tr th:first-child {
    border-top-left-radius: 10px;
} 
.header-table-row tr th:last-child {
    border-top-right-radius: 10px;
} 

.empty-results{
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
    font-style: italic;
    max-width: min(100vw, 1090px);
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    width: 100%;
}

.custom-date-picker{
    border-radius: 20px;
    border: 1px solid rgb(0, 57, 100);
}

.react-datepicker-wrapper{
    align-items: center;
}

.react-datepicker__calendar-icon {
    position: relative;
    top:2px
}